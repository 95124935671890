<template>
  <el-container class="passport-preview flex ai-c jc-c">
    <div class="passport-preview__content grid">
      <section class="passport-preview__wrapper">
        <div class="passport-preview__wrapper flex-column jc-c w-80 gap-2">
          <Icon iconName="iknowa-logo-v4.svg" iconHeight="36px" iconWidth="199px"/>
          <section class="flex-column gap-half">
            <Typography variant="h4">
              Your Iknowa Trade<br> Passport Is Complete 🎉
            </Typography>
            <Typography variant="p" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
              Here’s what it gives you;
            </Typography>
          </section>
          <section class="tour__wrapper flex-column gap-half">
            <div class="flex-row ai-c" v-for="(benefit, index) in benefits" :key="index">
              <Icon iconName="blue-check-filled-icon.svg" iconHeight="24px" iconWidth="24px"/>
              <Typography variant="p" textWeight="400">{{ benefit.label }}</Typography>
            </div>
          </section>
          <Button
            class="hide-in-mobile"
            :isActive="true"
            buttonText="complete 🚀"
            @handle-click="onComplete"/>
        </div>
        <Icon
          class="passport-preview__arrow hide-in-mobile"
          iconName="arrow-right-onboarding.svg"
          iconHeight="60px"
          iconWidth="111px"/>
        <Icon
          class="passport-preview__arrow show-in-mobile"
          iconName="arrow-down-onboarding.svg"
          iconHeight="88px"
          iconWidth="57px"/>
      </section>
      <PassportPreviewCard>
        <template #custom-footer>
          <Button
            class="show-in-mobile"
            :isActive="true"
            buttonText="complete 🚀"
            @handle-click="onComplete"/>
        </template>
      </PassportPreviewCard>
    </div>
  </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PassportPreviewCard from '@/core/components/common/cards/PassportPreviewCard.vue';
import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
// import { POST_ONBOARD } from '@/modules/post-onboarding/routes';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Icon,
    Typography,
    Button,
    PassportPreviewCard
  },

  data() {
    return {
      benefits: [
        {
          label: 'Value one'
        },
        {
          label: 'Value two'
        },
        {
          label: 'Value three'
        },
      ],
      DASHBOARD_ROUTE
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['isOnPassportOverview']),
  },

  methods: {
    ...mapActions(USERS_STORE, ['setNotInMainLayout']),

    onComplete() {
      // this.setNotInMainLayout(true);
      // this.$router.push({ name: POST_ONBOARD });

      this.setNotInMainLayout(false);
      this.$router.push({ name: DASHBOARD_ROUTE });
      // window.location.href = `/${DASHBOARD_ROUTE}`;
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.passport-preview {
  height: 100%;
  overflow-y: auto;

  &__content {
    min-width: 814px;
    min-height: 470px;
    max-height: 470px;
    height: 100%;
    background: transparent;
    grid-template-columns: 1fr 1fr;
  }

  &__wrapper {
    height: 100%;
    position: relative;
  }

  &__wrapper.card-wrapper {
    background: #FFF;
    padding: 2rem 1.5rem;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  &__wrapper-with-border-bottom {
    border-bottom: 1px solid #C5D2F2;
    padding-bottom: .5rem;
  }

  &__wrapper-with-border-bottom.grid-fill-70 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));;
  }

  &__wrapper-with-border-bottom.grid-fill-100 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));;
  }

  &__wrapper-with-border-bottom:last-child {
    border-bottom: none;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 20px;
  }

  &__check-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    background: #40AE42;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__category-icon {
    width: 40px;
    height: 40px;
    min-width: 16px;
    background: #C5D2F2;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__img {
    :deep(path) {
      fill: #264FD5;
      fill-opacity: 1;
    }
  }
}

@media screen and (max-width: 1080px) {
  .passport-preview {
    &__content {
      min-width: 700px;
    }
  }
}

@include media-sm-max-width() {
  .passport-preview {
    width: 100%;
    padding: 1.5rem 1.5rem 0 1.5rem;

    &__content {
      min-width: unset;
      max-height: unset;
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__wrapper.card-wrapper {
      border-radius: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    &__arrow {
      top: 70%;
      right: 20%;
    }
  }
}

@include media-xs-max-width() {
  .passport-preview {
    &__content {
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 390px) {
  .passport-preview {
    padding: 1.5rem 2rem 0 1rem;
  }
}
</style>
